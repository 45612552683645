import React, { useState, useContext } from "react"
import queryString from 'query-string'

import Page from "../../components/layout/Page"
import SEO from "../../components/seo"

import SimpleForm from "../../components/forms/SimpleForm"
import PasswordField from "../../components/fields/PasswordField";
import SuccessBox from "../../components/boxes/SuccessBox"

import { postData } from "../../services/server"
import { UserContext} from "../../state/UserContext"

const ResetPasswordPage = () => {
  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");

  const userContext = useContext(UserContext);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validation, setValidation] = useState({})
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const [reset, setReset] = useState(false);

  function resetPassword() {
    const problems = { error: "" }

    if (password.length === 0)
      problems.password = "Required";
    if (password2.length === 0)
      problems.password2 = "Required";
    else if (password != password2)
      problems.password2 = "Invalid";

    setValidation(problems);

    if (problems.password || problems.password2)
        setError("Please correct the highlighted problems.");
    else {
      setBusy(true);

      postData("/api/users/resetpassword", { 
          reset: params.uid,
          password: password
        },
        (response) => {
            userContext.login(response);
            setReset(true);
        },
        (error, status) => {
          console.log(error);
          if (status && status == 401)
            setError("Password reset has expired.")
          else
            setError("Error processing request.")
        },
        () => {
          setBusy(false);
        }
      );
    }
  }

  return (
    <>
        <SEO page="reset-password" />
        <Page padding={0} simple center>
          <>
          {reset ? 
              <>
                <SuccessBox>
                    Your password has been reset.
                </SuccessBox>
              </>
          :
            <SimpleForm
              title="Reset Password"
              instructions="Enter you new password below."
              error={error}
              busy={busy}
              disabled={password===""}
              onClick={resetPassword}
          >
              <PasswordField
                    name="password"
                    label="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(event.target.value)}
                    wide
                    error={validation.password ? true:false}
                    autoComplete="new-password"
                />

              <br/>
              <br/>

              <PasswordField
                    name="password2"
                    label="Confirm Password"
                    required
                    value={password2}
                    onChange={(e) => setPassword2(event.target.value)}
                    wide
                    error={validation.password2 ? true:false}
                    autoComplete="new-password"
                />
            </SimpleForm>
          }

          <br />
          <br />
          </>
        </Page>
    </>
  )
}

export default ResetPasswordPage